import styled from "styled-components"
import { mq, typography, zIndexes } from "styles"
import { Link } from "gatsby"

export const QuickAddProductItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Title = styled(Link)`
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  display: inline-block;
  margin-right: 16px;
  ${typography.body};

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const TitlePrice = styled.div`
  display: flex;
  ${mq.maxWidth("md")} {
    flex-direction: column;
  }
  justify-content: space-between;
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
`

export const Price = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
`

export const Description = styled.p`
  padding-top: 2px;
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
  ${typography.bodySmall};
`

export const SelectorsContainer = styled.form`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
`

export const ImgContainer = styled.div`
  position: relative;
  margin-right: 16px;

  .gatsby-image-wrapper {
    height: 100%;
    width: 150px;
    ${mq.minWidth("sm")} {
      width: 180px;
    }
  }
`

export const ProductInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const QuickAddProductItemFAB = styled.div`
  z-index: ${zIndexes.zIndex1TextAboveImage};
  position: absolute;
  bottom: 12px;
  left: 12px;
  ${mq.minWidth("md")} {
    width: 40px;
    height: 40px;
    & > button {
      width: 100%;
      height: 100%;
    }
  }
`
