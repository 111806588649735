import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { addSlash } from "utils/stringUtils"
import {
  Title,
  Description,
  ImgContainer,
  Price,
  SelectorsContainer,
  QuickAddProductItemContainer,
  TitlePrice,
  QuickAddProductItemFAB,
  HorizontalContainer,
  ProductInfoContainer,
} from "./QuickAddProductItem.styles"
import { useVariantOptions } from "hooks/useVariantOptions"
import { usdFmt } from "utils/priceUtils"
import { tracking } from "utils/tracking"
import { generatePriceRange } from "utils/productUtils"
import { ModeType, ProductListItemType } from "typings/modules"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { useContext } from "react"
import { StoreContext } from "contexts/StoreContext"
import {
  LargeAndUp,
  MediumAndBelow,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"
import { ButtonVariant } from "components/UI/Button/Button"
import { FAB } from "components/FAB/FAB"
import { VariantOptions } from "components/Products/VariantOptions"

type productItemProps = {
  item: ProductListItemType
  mode?: ModeType
  onAddToCartClick?: (variantId: string) => void
  onSelectedVariantChanged?: (variantId: string) => void
  preAddToCartClick?: () => void
}

export const QuickAddProductItem: React.FC<productItemProps> = ({
  item,
  mode = "dark",
  onAddToCartClick,
  onSelectedVariantChanged,
  preAddToCartClick,
  ...props
}) => {
  const {
    selectedVariantId,
    selectedVariant,
    image,
    imageAlt,
    variantTypes,
    updateSelectedVariant,
    selectableOptions,
  } = useVariantOptions(item)

  useEffect(() => onSelectedVariantChanged?.(selectedVariant?.id), [
    selectedVariant?.id,
  ])

  const availableForSale = selectedVariant?.availableForSale

  const { addVariantToCart, setCartOpen } = useContext(StoreContext)

  const productPathPrefix = useProductPathPrefix()
  const handle = item?.item?.product?.shopifyProduct?.handle ?? ""
  const link = addSlash(`${productPathPrefix}${handle}`)
  const productLink = selectedVariantId
    ? `${link}?variant=${selectedVariantId}`
    : link
  const price = selectedVariant?.price ?? ""
  const usdPrice = price ? usdFmt(price) : ""
  const priceRange = generatePriceRange(item.item, item?.product)

  const trackPDPLink = () => {
    tracking.productClicked(item?.item?.product, selectedVariant, productLink)
  }
  const description = item.item.description
  const handleAddToCartClick = () => {
    preAddToCartClick?.()

    if (onAddToCartClick) {
      onAddToCartClick(selectedVariant.id)
    } else {
      addVariantToCart(selectedVariant.id, 1)
      setCartOpen(true)
    }
  }

  const selectors = (
    <SelectorsContainer
      onSubmit={event => {
        event.preventDefault()
      }}
    >
      <VariantOptions
        selectedVariant={selectedVariant}
        updateSelectedVariant={updateSelectedVariant}
        selectableOptions={selectableOptions}
        mode={mode}
        config={{ swatch: { renderLabel: true } }}
      />
    </SelectorsContainer>
  )
  if (!image) return null
  return (
    <QuickAddProductItemContainer {...props}>
      <HorizontalContainer>
        <ImgContainer>
          <QuickAddProductItemFAB>
            <FAB
              variant={`primary-${mode}` as ButtonVariant}
              disabled={!availableForSale}
              onClick={handleAddToCartClick}
            />
          </QuickAddProductItemFAB>
          <GatsbyImage image={image?.gatsbyImage} alt={imageAlt}></GatsbyImage>
        </ImgContainer>
        <ProductInfoContainer>
          <div>
            <TitlePrice>
              <Title to={productLink} onClick={trackPDPLink}>
                {item.item.title}
              </Title>
              <Price>{priceRange ? priceRange : usdPrice}</Price>
            </TitlePrice>
            {!!description && <Description>{description}</Description>}
          </div>
          {variantTypes.length > 0 && <LargeAndUp>{selectors}</LargeAndUp>}
        </ProductInfoContainer>
      </HorizontalContainer>
      {variantTypes.length > 0 && <MediumAndBelow>{selectors}</MediumAndBelow>}
    </QuickAddProductItemContainer>
  )
}
