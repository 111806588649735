import styled, { css } from "styled-components"
import { mq } from "styles"
import { BasicLink } from "components/UI/BasicLink/BasicLink"
import { typography } from "styles/typography"
import { Carousel as OriginalCarousel } from "components/UI/Carousel/Carousel"
import { UGCItem as OriginalUGCItem } from "components/UGCItem/UGCItem"
import { $BgColor } from "typings/modules"
import LeftArrow from "assets/svg/left-arrow.svg"
import RightArrow from "assets/svg/right-arrow.svg"
import Instagram from "assets/svg/instagram.svg"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

export const Container = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 96px 0;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  margin-bottom: 32px;
  text-align: center;

  ${mq.minWidth("sm")} {
    margin-bottom: 48px;
  }

  ${mq.minWidth("md")} {
    margin-bottom: 64px;
  }
`

export const Carousel = styled(OriginalCarousel)`
  padding-bottom: 0;

  .slick-slide {
    cursor: default;
  }

  .slick-slide > div > div {
    height: 240px;
    width: 240px;
    margin-left: 15px;
    margin-right: 15px;

    ${mq.minWidth("lg")} {
      width: 280px;
      height: 280px !important;
    }
  }
`

const commonArrowCss = css`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  z-index: 9;
  background-color: ${({ theme }) =>
    theme.dark.button?.primary?.fill?.opacityHex};
  transition: background-color 0.3s ease;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) =>
      theme.dark.button?.primary?.hoverFill?.opacityHex};
  }

  &::before {
    display: none;
  }
`

export const CarouselLeftArrow = styled(NoStyleButton)`
  &.slick-prev {
    ${commonArrowCss};
    left: 30px;
  }
`
export const CarouselRightArrow = styled(NoStyleButton)`
  &.slick-next {
    ${commonArrowCss};
    right: 30px;
  }
`

const commonArrowIconCss = css`
  color: ${({ theme }) => theme.light.text?.primary?.opacityHex};
  transition: background-color 0.3s ease;

  &:hover {
    ${mq.minWidth("lg")} {
      background-color: ${({ theme }) =>
        theme.dark.button?.primary?.hoverFill?.opacityHex};
    }
  }
`

export const CarouselLeftArrowIcon = styled(LeftArrow)`
  ${commonArrowIconCss}
`

export const CarouselRightArrowIcon = styled(RightArrow)`
  ${commonArrowIconCss}
`

export const CarouseUGCItem = styled(OriginalUGCItem)`
  margin-left: auto;
  margin-right: auto;
`

export const LinkIcon = styled(Instagram)`
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  max-width: 24px;
  max-height: 24px;
  transition: color 0.3s ease;
`

export const LinkText = styled.span`
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  border-bottom: 1px solid
    ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  margin-bottom: -2px;
  transition: border 0.3s ease;

  ${typography.body};
`

export const LinkContainer = styled(BasicLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 32px;
  gap: 10px;
  border: none;

  ${mq.minWidth("sm")} {
    margin-top: 48px;
  }

  ${mq.minWidth("md")} {
    margin-top: 64px;
  }
`
