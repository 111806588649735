import React, { useContext, useState } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { SanityUgcList, SanityUgcItem } from "typings/graphql"
import { ModeType } from "typings/modules"
import { Grid } from "components/UI/Grid/Grid"
import { mq } from "styles"
import { tracking } from "utils/tracking"
import {
  Container,
  Title,
  LinkContainer,
  LinkIcon,
  LinkText,
  Carousel,
  CarouselLeftArrow,
  CarouselRightArrow,
  CarouseUGCItem,
  CarouselLeftArrowIcon,
  CarouselRightArrowIcon,
} from "./UGCList.styles"
import { ProductFlyout } from "components/ProductFlyout/ProductFlyout"
import { PortableText } from "components/UI/PortableText/PortableText"

type Props = {
  index: number
} & SanityUgcList

export const UGCList = (sanityProps: Props) => {
  const themeContext = useContext(ThemeContext)
  const [selectedUgcItem, setSelectedUgcItem] = useState<SanityUgcItem>()
  const mode = sanityProps.mode as ModeType
  const backgroundColor = sanityProps.backgroundColor.opacityHex
  const hasLink =
    sanityProps.linkUrl && (sanityProps.showLinkIcon || sanityProps.linkText)

  const handleOpenUpsellFlyout = (newSelectedUgcItem: SanityUgcItem) => {
    tracking.elementClicked(newSelectedUgcItem.internalName, undefined, 1)
    setSelectedUgcItem(newSelectedUgcItem)
  }
  const handleCloseUpsellFlyout = () => setSelectedUgcItem(undefined)

  return (
    <>
      <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
        <Container $bgColor={backgroundColor}>
          {sanityProps._rawTitle && (
            <Grid base="1 [10] 1" md="2 [12] 2" lg="6 [12] 6">
              <Title>
                <PortableText blocks={sanityProps._rawTitle} $mode={mode} />
              </Title>
            </Grid>
          )}

          <Carousel
            settings={{
              dots: false,
              centerMode: true,
              infinite: true,
              variableWidth: true,
              draggable: false,
              slidesToShow: 1,
              prevArrow: (
                <CarouselLeftArrow aria-label="Previous Slide Button">
                  <CarouselLeftArrowIcon />
                </CarouselLeftArrow>
              ),
              nextArrow: (
                <CarouselRightArrow aria-label="Next Slide Button">
                  <CarouselRightArrowIcon />
                </CarouselRightArrow>
              ),
              arrows: true,
              responsive: [
                {
                  breakpoint: mq.breakpoints.lg - 1,
                  settings: {
                    draggable: true,
                    arrows: false,
                  },
                },
              ],
            }}
          >
            {sanityProps.ugcItems.map((ugcItem, index) => (
              <CarouseUGCItem
                key={`ugc-item-${ugcItem.id}-${index}`}
                item={ugcItem}
                onClick={handleOpenUpsellFlyout}
              />
            ))}
          </Carousel>

          {hasLink && (
            <Grid base="1 [10] 1" md="2 [12] 2" lg="6 [12] 6">
              <LinkContainer
                aria-label={`Go to ${
                  sanityProps.title || sanityProps.linkText
                }`}
                to={sanityProps.linkUrl}
              >
                {sanityProps.showLinkIcon && (
                  <LinkIcon role="img" aria-label="Instagram Logo" />
                )}
                {sanityProps.linkText && (
                  <LinkText>{sanityProps.linkText}</LinkText>
                )}
              </LinkContainer>
            </Grid>
          )}
        </Container>

        {!!selectedUgcItem && (
          <ProductFlyout
            isOpen={!!selectedUgcItem}
            productItems={selectedUgcItem?.highlightedProductItems}
            onClose={handleCloseUpsellFlyout}
            item={selectedUgcItem}
            title="Shop this setup"
          />
        )}
      </ThemeProvider>
    </>
  )
}
