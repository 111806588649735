import styled, { css } from "styled-components"
import { mq, typography, zIndexes } from "styles"
import { QuickAddProductItem } from "components/ProductItem/QuickAddProductItem"
import { Button } from "components/UI/Button/Button"
import CloseSvg from "assets/svg/close.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import { siteConstants } from "constants/site"

const productFlyoutContainerCss = css`
  margin: 0 60px 0px 60px;

  ${mq.maxWidth("lg")} {
    margin: 0 48px 0px 48px;
  }

  ${mq.maxWidth("md")} {
    margin: 0 30px 0px 30px;
  }
`

export const ProductFlyoutHeader = styled.div`
  ${productFlyoutContainerCss}
  padding-top: 24px;
  padding-bottom: 30px;

  display: flex;
  justify-content: space-between;
`

export const ProductsContainer = styled.div`
  ${productFlyoutContainerCss}
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ProductFlyoutTitle = styled.h5`
  color: ${({ theme }) => theme.dark.text.primary.opacityHex};
`

export const ProductFlyoutCloseIcon = styled(CloseSvg)`
  cursor: pointer;
  width: 15px;
  height: 15px;
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
`

export const StyledProduct = styled(QuickAddProductItem)`
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};

  &:last-of-type {
    border-bottom: none;
  }
`

export const FlyoutContainer = styled.div`
  height: calc(100vh - ${siteConstants.butterBarHeight - 1}px);
  display: grid;
  grid-template-rows: auto 1fr auto;
`

export const ProductFlyoutAddAllButton = styled(Button)`
  position: sticky;
  bottom: 0;
  z-index: ${zIndexes.zIndex1TextAboveImage + 1};
`

export const Img = styled(GatsbyImage)`
  ${mq.minWidth("md")} {
    width: 360px;
    height: 360px;
  }
`

export const Caption = styled.div`
  ${typography.h6};
  color: ${({ theme }) => theme.dark.text.secondary?.opacityHex};
  margin: 24px 0 16px;
  ${mq.minWidth("md")} {
    margin-top: 32px;
  }
`

export const Credit = styled.p`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark.text.primary?.opacityHex};
  margin-top: 8px;
`
