import React from "react"
import { Maybe, SanityUgcItem } from "typings/graphql"
import { BasicLink } from "components/UI/BasicLink/BasicLink"
import {
  Container,
  Image,
  Overlay,
  OverlayText,
  WrapperButton,
} from "./UGCItem.styles"
import { getImage } from "utils/imageUtils"

type Props = {
  item: Maybe<SanityUgcItem>
  onClick: (ugcItem: SanityUgcItem) => void
  className?: string
}

export const UGCItem = ({ item, onClick, className }: Props) => {
  const hasProducts = !!item.highlightedProductItems?.length
  const hasLink = !!item.linkUrl
  const itemImage = getImage(item.image)
  const clickable = hasLink || hasProducts

  let contentDOM = (
    <>
      <Image
        image={itemImage?.gatsbyImage}
        alt={itemImage?.description || `Image for ${item.internalName}`}
        imgStyle={{
          objectFit: "cover",
          objectPosition: "50% 50%",
        }}
      />

      {!hasLink && hasProducts && (
        <Overlay>
          <OverlayText>Shop this</OverlayText>
        </Overlay>
      )}
    </>
  )

  if (hasLink) {
    contentDOM = <BasicLink to={item.linkUrl}>{contentDOM}</BasicLink>
  } else if (hasProducts) {
    contentDOM = (
      <WrapperButton onClick={() => onClick(item)}>{contentDOM}</WrapperButton>
    )
  }

  return (
    <Container $clickable={clickable} className={className}>
      {contentDOM}
    </Container>
  )
}
