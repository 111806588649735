import React, { useContext, useRef, useState } from "react"
import { SanityProductItem, SanityUgcItem } from "typings/graphql"
import { Flyout } from "components/UI/Flyout/Flyout"
import { StoreContext } from "contexts/StoreContext"
import { useProductList } from "hooks/useProductList"
import {
  ProductFlyoutAddAllButton,
  FlyoutContainer,
  ProductFlyoutCloseIcon,
  ProductFlyoutHeader,
  ProductFlyoutTitle,
  ProductsContainer,
  StyledProduct,
  Img,
  Credit,
  Caption,
} from "./ProductFlyout.styles"
import { NotificationBar } from "components/UI/NotificationBar/NotificationBar"
import { getImage } from "utils/imageUtils"

type Props = {
  productItems: SanityProductItem[]
  title?: string
  item?: SanityUgcItem
} & React.ComponentProps<typeof Flyout>

export const ProductFlyout: React.FC<Props> = ({
  productItems,
  onClose,
  title = "Shop This Look",
  item,
  ...props
}) => {
  const { plItems: products, loading } = useProductList(productItems)
  const { addVariantToCart, addLineItems } = useContext(StoreContext)
  const [notificationText, setNotificationText] = useState(null)
  const [selectedVariants, setSelectedVariants] = useState({})
  const productsRef = useRef()

  const itemAddedToCart = (variantId: string, productTitle: string) => {
    showNotification(`${productTitle} was added to your cart.`)
    addVariantToCart(variantId, 1)
  }

  const addAllToCart = async () => {
    const variants = Object.values(selectedVariants).map(
      (variantId: string) => ({
        variantId,
        quantity: 1,
      })
    )

    showNotification("All products were added to your cart.")
    addLineItems(variants)
  }

  const showNotification = (message: string) => {
    setNotificationText(message)
    setTimeout(() => setNotificationText(null), 1300)
  }

  const credit = item?.photoCredit
  const image = getImage(item?.image)

  return (
    <Flyout onClose={onClose} scrollableRef={productsRef} {...props}>
      <NotificationBar message={notificationText} />
      <FlyoutContainer>
        <ProductFlyoutHeader>
          <ProductFlyoutTitle>{title}</ProductFlyoutTitle>
          <ProductFlyoutCloseIcon onClick={() => onClose()} />
        </ProductFlyoutHeader>
        <ProductsContainer ref={productsRef}>
          {!loading && (
            <div>
              {image?.gatsbyImage && (
                <Img
                  image={image?.gatsbyImage}
                  alt={item?.photoCredit || "Image with listed products"}
                />
              )}
              {credit && (
                <>
                  <Credit>{credit}</Credit>
                  <Caption>Highlighted products</Caption>
                </>
              )}
              {products
                ?.filter(product => product?.product?.id)
                .map(product => (
                  <StyledProduct
                    key={product.product.id}
                    item={product}
                    onAddToCartClick={variantId =>
                      itemAddedToCart(variantId, product.product.title)
                    }
                    onSelectedVariantChanged={(variantId: string) => {
                      if (!variantId) {
                        return
                      }

                      setSelectedVariants(prevState => ({
                        ...prevState,
                        [product.product.id]: variantId,
                      }))
                    }}
                  />
                ))}
            </div>
          )}
        </ProductsContainer>
        <ProductFlyoutAddAllButton
          trackingId={"product-flyout-add-all-to-cart"}
          fullWidth
          onClick={addAllToCart}
        >
          Add All to Cart
        </ProductFlyoutAddAllButton>
      </FlyoutContainer>
    </Flyout>
  )
}
