import React from "react"
import { SanityUgcList } from "typings/graphql"
import { UGCList } from "./UGCList"

export const SanityUgcListBlock = ({
  index,
  fields,
}: {
  fields: SanityUgcList
  index: number
}) => <UGCList index={index} {...fields} />

export default SanityUgcListBlock
