import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { mq, typography } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

type $Clickable = { $clickable: boolean }

export const OverlayText = styled.span`
  transition: border 0.3s ease;
  border-bottom: 1px solid
    ${({ theme }) => theme.light.text?.primary?.opacityHex};
  color: ${({ theme }) => theme.light.text?.primary?.opacityHex};
  padding-bottom: 3px;

  ${typography.body};

  ${mq.minWidth("lg")} {
    border-bottom: 0;
    padding-bottom: 0;
  }
`

export const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 16px 0;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0) 0%,
    rgba(0, 0, 0, 0.32) 100%
  );

  ${mq.minWidth("lg")} {
    padding: 24px 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`

export const WrapperButton = styled(NoStyleButton)`
  width: 100%;
  height: 100%;
`

export const Container = styled.div<$Clickable>`
  position: relative;
  display: block;
  cursor: ${({ $clickable }) => `${$clickable ? "pointer" : "default"}`};

  ${mq.minWidth("lg")} {
    &:hover ${Overlay} {
      opacity: 1;
    }
  }
`

export const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`
